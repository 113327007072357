import React, { Component } from 'react';

import { Provider } from 'react-redux';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import MenuPanel from './components/MenuPanel';
import BarreInformation from './components/BarreInformation';
import configureStore from './store/configureStore';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <>
      <BarreInformation params={window.location.search} />
      <MenuPanel />
    </>
  </Provider>
);

export default App;
