import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Grid,
  Image,
  Menu,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';

import PanelTest from './PanelTest';
import PanelGraphique from './PanelGraphique';
import PanelHistorique from './PanelHistorique';

import * as actions from '../store/actions';

import logo from '../assets/images/logo.svg';

/**
 * Menu of the different panels.
 *
 * @author Axel MORLET
 * @class MenuPanel
 * @extends {React.Component}
 */
class MenuPanel extends React.Component {
  /**
     * Prop types.
     *
     * @static
     * @memberof MenuPanel
     */
  static propTypes = {
    /* Array of data */
    data: PropTypes.array,
  };

  /**
     * Default props.
     *
     * @static
     * @memberof MenuPanel
     */
  static defaultProps = {
    data: [],
    params: '',
  };

  /**
     * Creates an instance of MenuPanel.
     *
     * @param {any} args
     * @memberof MenuPanel
     */
  constructor(...args) {
    super(...args);
    this.state = { activeItem: this.props.panel };
  }

  /**
     * Handle item click on menu.
     *
     * @param {any} args
     * @memberof MenuPanel
     */
  handleItemClick = (e, { name }) => this.props.loadPanel(name);

  /**
     * Render the menu.
     *
     * @param {any} args
     * @memberof MenuPanel
     */
  getComponentOfMenu() {
    const { panel } = this.props;
    let component;
    switch (panel) {
      case 'Lancer un test':
        component = <PanelTest />;
        break;
      case 'Historique':
        component = <PanelHistorique />;
        break;
      case 'Graphique':
        component = <PanelGraphique />;
        break;
      default:
        break;
    }
    return component;
  }

  /**
     * Render the menu item.
     *
     * @param {any} args
     * @memberof MenuPanel
     */
  renderMenuItem = () => {
    const { data, panel } = this.props;

    return (
      <Menu as={Grid} pointing fluid vertical secondary stackable>
        <Menu.Item name="Lancer un test" content="Lancer un test" active={panel === 'Lancer un test'} onClick={this.handleItemClick} />
        {data.length === 0 || !_.isArray(data) ? <Menu.Item disabled name="Historique" active={panel === 'Historique'} onClick={this.handleItemClick} /> : <Menu.Item name="Historique" active={panel === 'Historique'} onClick={this.handleItemClick} /> }
        {data.length === 0 || !_.isArray(data) ? <Menu.Item disabled name="Graphique" active={panel === 'Graphique'} onClick={this.handleItemClick} /> : <Menu.Item name="Graphique" active={panel === 'Graphique'} onClick={this.handleItemClick} />}
      </Menu>
    );
  };

  /**
     * Render the component.
     *
     * @param {any} args
     * @memberof MenuPanel
     */
  render() {
    return (
      <Grid style={{ display: 'flex', flex: 1, margin: 0 }}>
        <Grid.Column width={2} style={{ paddingRight: 0, backgroundColor: '#EBF2FA' }}>
          {this.renderMenuItem()}
          <Image
            src={logo}
            style={{ position: 'absolute', bottom: 0 }}
            fluid
          />
        </Grid.Column>
        <Grid.Column style={{ padding: 0 }} stretched width={14}>
          <Segment
            style={{
              marginTop: 0,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              backgroundColor: '#EBF2FA',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            {this.getComponentOfMenu()}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.allSpeedtest,
  panel: state.panelOpen,
});

const mapDispatchToProps = {
  loadPanel: actions.panelOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPanel);
