import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import {
  Segment,
  Grid,
  Menu,
  Button,
} from '@jvs-group/jvs-mairistem-composants';

import Speedtest from './speedtest';
import './BarreInformation.css';

import * as actions from '../store/actions';

const PanelTest = (props) => {
  const [running, setRunning] = React.useState(false);
  let uiData = null;
  const s = React.useRef(new Speedtest());
  const meterBk = /Trident.*rv:(\d+\.\d+)/i.test(navigator.userAgent) ? '#EAEAEA' : '#80808040';
  const dlColor = '#6060AA';
  const ulColor = '#616161';
  const progColor = meterBk;

  React.useEffect(() => {
    const baseUrl = `https://${window.location.host}/api/speedtest`;

    s.current.setParameter('url_dl', `${baseUrl}/garbage.php`);
    s.current.setParameter('url_ul', `${baseUrl}/empty.php`);
    s.current.setParameter('url_ping', `${baseUrl}/empty.php`);
    s.current.setParameter('url_getIp', `${baseUrl}/getIP.php`);
    s.current.setParameter('mpot', true);

    window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || (function (callback, element) { setTimeout(callback, 1000 / 60); });
  }, []);

  const I = (i) => document.getElementById(i);

  const drawMeter = (c, amount, bk, fg, progress, prog) => {
    var ctx = c.getContext('2d');
    var dp = window.devicePixelRatio || 1;
    var cw = c.clientWidth * dp; var
      ch = c.clientHeight * dp;
    var sizScale = ch * 0.0055;
    if (c.width == cw && c.height == ch) {
      ctx.clearRect(0, 0, cw, ch);
    } else {
      c.width = cw;
      c.height = ch;
    }
    ctx.beginPath();
    ctx.strokeStyle = bk;
    ctx.lineWidth = 12 * sizScale;
    ctx.arc(c.width / 2, c.height - 58 * sizScale, c.height / 1.8 - ctx.lineWidth, -Math.PI * 1.1, Math.PI * 0.1);
    ctx.stroke();
    ctx.beginPath();
    ctx.strokeStyle = fg;
    ctx.lineWidth = 12 * sizScale;
    ctx.arc(c.width / 2, c.height - 58 * sizScale, c.height / 1.8 - ctx.lineWidth, -Math.PI * 1.1, amount * Math.PI * 1.2 - Math.PI * 1.1);
    ctx.stroke();
    if (typeof progress !== 'undefined') {
      ctx.fillStyle = prog;
      ctx.fillRect(c.width * 0.3, c.height - 16 * sizScale, c.width * 0.4 * progress, 4 * sizScale);
    }
  };

  const mbpsToAmount = (s) => 1 - (1 / (1.3 ** Math.sqrt(s)));
  const format = (d) => {
    d = Number(d);
    if (d < 10) return d.toFixed(2);
    if (d < 100) return d.toFixed(1);
    return d.toFixed(0);
  };
  const getTimestamp = () => {
    const timestamp = new Date();
    let day = timestamp.getDate();
    if (day < 10) { day = `0${day}`; }
    let month = timestamp.getMonth() + 1;
    if (month < 10) { month = `0${month}`; }
    const year = timestamp.getFullYear();
    let hours = timestamp.getHours();
    if (hours < 10) { hours = `0${hours}`; }
    let minutes = timestamp.getMinutes();
    if (minutes < 10) { minutes = `0${minutes}`; }
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const startStop = () => {
    const codeCollectivite = document.getElementById('codeCollectivite').value;
    const identiteCollectivite = !_.isNil(document.getElementById('identiteCollectivite')) ? _.split(document.getElementById('identiteCollectivite').textContent, ' (')[0] : null;
    if (!_.isNil(codeCollectivite) && !_.isEmpty(codeCollectivite)) {
      if (s.current.getState() == 3) {
      // speedtest is running, abort
        s.current.abort();
        //   data = null;
        //   I('startStopBtn').className = '';
        initUI();
      } else {
      // test is not running, begin
        //   I('startStopBtn').className = 'running';
        s.current.onupdate = function (data) {
          uiData = data;
          frame();
        };
        s.current.onend = function (aborted) {
        // I('startStopBtn').className = '';
          updateUI(true);
          setRunning(false);
          props.create(
            codeCollectivite,
            identiteCollectivite,
            getTimestamp(),
            navigator.userAgent,
            document.getElementById('dlText').textContent,
            document.getElementById('ulText').textContent,
            document.getElementById('pingText').textContent,
            document.getElementById('jitText').textContent,
          );
        };
        s.current.start();
        setRunning(true);
      }
    }
  };

  const updateUI = (forced) => {
    if (!forced && s.current.getState() != 3) return;
    if (uiData == null) return;
    var status = uiData.testState;
    // I('ip').textContent = uiData.clientIp;
    I('dlText').textContent = (status == 1 && uiData.dlStatus == 0) ? '...' : format(uiData.dlStatus);
    drawMeter(I('dlMeter'), mbpsToAmount(Number(uiData.dlStatus * (status == 1 ? oscillate() : 1))), meterBk, dlColor, Number(uiData.dlProgress), progColor);
    I('ulText').textContent = (status == 3 && uiData.ulStatus == 0) ? '...' : format(uiData.ulStatus);
    drawMeter(I('ulMeter'), mbpsToAmount(Number(uiData.ulStatus * (status == 3 ? oscillate() : 1))), meterBk, ulColor, Number(uiData.ulProgress), progColor);
    I('pingText').textContent = format(uiData.pingStatus);
    I('jitText').textContent = format(uiData.jitterStatus);
  };

  const oscillate = () => 1 + 0.02 * Math.sin(Date.now() / 100);
  const frame = () => {
    requestAnimationFrame(frame);
    updateUI();
  };

  const initUI = () => {
    drawMeter(I('dlMeter'), 0, meterBk, dlColor, 0);
    drawMeter(I('ulMeter'), 0, meterBk, ulColor, 0);
    I('dlText').textContent = '';
    I('ulText').textContent = '';
    I('pingText').textContent = '';
    I('jitText').textContent = '';
    // I('ip').textContent = '';
  };

  return (
    <Segment style={{ flex: 1 }}>
      <Grid as={Menu} text style={{ height: '100%' }}>
        <Grid.Row className="testGroup" centered>
          <Menu.Item as={Grid.Column} textAlign="center" className="testArea" width={8}>
            <div className="testName">Download</div>
            <canvas id="dlMeter" className="meter" />
            <div id="dlText" className="meterText" />
            <div className="unit">Mbps</div>
          </Menu.Item>
          <Menu.Item as={Grid.Column} textAlign="center" className="testArea" width={8}>
            <div className="testName">Upload</div>
            <canvas id="ulMeter" className="meter" />
            <div id="ulText" className="meterText" />
            <div className="unit">Mbps</div>
          </Menu.Item>
        </Grid.Row>
        <Grid.Row centered>
          <Button
            id="startStopBtn"
            content={!running ? 'Démarrer le test' : 'Annuler'}
            color={!running ? null : 'red'}
            onClick={startStop}
            style={{ height: 40 }}
          />
        </Grid.Row>
        <Grid.Row className="testGroup" centered>
          <Menu.Item as={Grid.Column} textAlign="center" className="testArea" width={8}>
            <div className="testName">Ping</div>
            <div id="pingText" className="meterText" style={{ color: '#AA6060' }} />
            <div className="unit">ms</div>
          </Menu.Item>
          <Menu.Item as={Grid.Column} textAlign="center" className="testArea" width={8}>
            <div className="testName">Jitter</div>
            <div id="jitText" className="meterText" style={{ color: '#AA6060' }} />
            <div className="unit">ms</div>
          </Menu.Item>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapDispatchToProps = {
  create: actions.speedtestCreate,
};

export default connect(null, mapDispatchToProps)(PanelTest);
