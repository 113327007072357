import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Segment,
  Button,
  Grid,
  Menu,
  Header,
} from '@jvs-group/jvs-mairistem-composants';
import { Input } from 'semantic-ui-react';

import * as actions from '../store/actions';

/**
 * Information bar.
 *
 * @author Axel MORLET
 * @class BarreInformation
 * @extends {React.Component}
 */
class BarreInformation extends React.Component {
  /**
     * Prop types.
     *
     * @static
     * @memberof BarreInformation
     */
  static propTypes = {
    /* Parameters of the app, useful for the manager */
    params: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      value: null,
      params: [],
      isOpen: true,
      prospect: false,
      identite: '',
    };
  }

  componentDidMount() {
    const { params } = this.props;

    if (!_.isNil(params) && params !== '') {
      const queryparams = params.split('?')[1];
      const parameters = queryparams.split('&');
      let pair = null;
      _.map(parameters, (param) => {
        pair = param.split('=');
        if (pair[0] === 'collectivite') {
          this.setState({ value: pair[1] });
        } else if (pair[0] === 'identite') {
          this.setState({ identite: pair[1] });
        }
      });
    }
  }

  /**
     * Handler onClick.
     *
     * @memberof BarreInformation
     */
  handleClick = () => {
    const {
      value,
      params,
    } = this.state;
    if (_.isNil(value)) {
      this.props.fetchAll(params);
    } else {
      this.props.fetchAll(value);
    }
  };

  /**
     * Handle change inputs.
     *
     * @memberof BarreInformation
     */
  handleChange = (event) => {
    const { value } = event.target;
    if (RegExp('^[0-9]{0,5}$').test(value)) {
      if (value === '99999') {
        this.setState({ prospect: true });
      } else {
        this.setState({ prospect: false });
      }
      this.setState({ value });
    }
  };

  /**
     * Handle key pres (enter for research).
     *
     * @memberof BarreInformation
     */
  handleKeyPress = (event) => {
    const { value } = event.target;
    const { key } = event;
    if (key === 'Enter') {
      this.setState({ value }, () => {
        this.handleClick();
      });
    }
  };

  /**
     * Handle change identité
     *
     * @memberof BarreInformation
     */
  handleChangeIdentite = (event) => {
    const { value } = event.target;
    this.setState({ identite: value });
  };

  /**
     * Render input for prospect name.
     *
     * @memberof BarreInformation
     */
  renderProspect = () => {
    const { identite } = this.state;

    return (
      <>
        <Menu.Item as={Grid.Column} width={4}>
          <Input
            placeholder="Identité"
            value={identite}
            onChange={this.handleChangeIdentite}
          />
        </Menu.Item>
        {this.renderInputCollectiviteSearch()}
      </>
    );
  };

  renderInputCollectiviteSearch = () => {
    const { value } = this.state;

    return (
      <>
        <Menu.Item>
          <Input
            icon="user"
            iconPosition="left"
            value={!_.isNil(value) ? value : ''}
            id="codeCollectivite"
            placeholder="Code collectivité"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
        </Menu.Item>
        <Menu.Item>
          <Button type="submit" onClick={this.handleClick}>Rechercher</Button>
        </Menu.Item>
      </>
    );
  };

  /**
     * Render the component.
     *
     * @returns
     * @memberof BarreInformation
     */
  render() {
    const {
      value,
      prospect,
      identite,
    } = this.state;

    return (
      <Segment style={{ backgroundColor: '#109FDB', borderRadius: 0, marginBottom: 0 }}>
        <Menu as={Grid} text floated fluid>
          <Grid.Row columns={3} style={{ padding: 0 }}>
            <Menu.Item as={Grid.Column} width={3} position="left">
              {identite !== '' && <Header id="identiteCollectivite">{`${identite} (${value})`}</Header> }
            </Menu.Item>
            <Menu.Item as={Grid.Column} width={4}>
              {prospect
                            && (
                            <Input
                              placeholder="Identité"
                              value={!_.isNil(identite) ? identite : ''}
                              onChange={this.handleChangeIdentite}
                            />
                            )}
            </Menu.Item>
            <Menu.Item as={Grid.Column} width={9}>
              {this.renderInputCollectiviteSearch()}
            </Menu.Item>
          </Grid.Row>
        </Menu>
      </Segment>
    );
  }
}

const mapDispatchToProps = {
  fetchAll: actions.speedtestsFetchAll,
  panel: actions.panelOpen,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BarreInformation);
