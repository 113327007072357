import * as constants from '../constants/speedtests';

const initialState = {
  allSpeedtest: [],
  loading: false,
  error: null,
  currentPage: null,
  hasMoreItems: true,
  panelOpen: 'Lancer un test',
};

export default function speedtestReducer(state = initialState, action) {
  switch (action.type) {
    /**
         * Fetch All
         */
    case constants.SPEEDTEST_FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.SPEEDTEST_FETCH_ALL_SUCCESS:
      return {
        ...state,
        allSpeedtest: action.speedtests,
        loading: false,
      };

    case constants.SPEEDTEST_FETCH_ALL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

      /**
         * Delete
         */
    case constants.SPEEDTEST_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.SPEEDTEST_DELETE_SUCCESS:
      return {
        ...state,
        allSpeedtest: [],
        panelOpen: 'Lancer un test',
        currentPage: 1,
        last_page: 1,
        loading: false,
      };

    case constants.SPEEDTEST_DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

      /**
         * Panel Open
         */

    case constants.PANEL_OPEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.PANEL_OPEN_SUCCESS:
      return {
        ...state,
        panelOpen: action.panel,
        loading: false,
      };
    case constants.PANEL_OPEN_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
