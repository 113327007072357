import {
  all, call, put, fork, takeLatest, select,
} from 'redux-saga/effects';
import * as actions from '../actions/speedtests';
import * as constants from '../constants/speedtests';

const url = '/api';

const getData = (state) => state.allSpeedtest;

async function getAllSpeedtests(codeCollectivite) {
  const response = await fetch(`${url}?collectivite=${codeCollectivite}`);
  if (response.status !== 200) {
    throw response.statusText;
  }
  return response.json();
}

async function getDelete(codeCollectivite) {
  const response = await fetch(`${url}?collectivite=${codeCollectivite}`, {
    method: 'DELETE',
  });

  if (response.status !== 204) {
    throw response.statusText;
  }
  return response;
}

async function getCreate(action) {
  const response = await fetch(`${url}?collectivite=${action.codeCollectivite}&identite=${action.identiteCollectivite}&timestamp=${action.timestamp}&ua=${action.ua}&dl=${action.dl}&ul=${action.ul}&ping=${action.ping}&jitter=${action.jitter}`, {
    method: 'POST',
  });

  if (response.status !== 201) {
    throw response.statusText;
  }
  return response.json();
}

function* fetchAllSpeedtests(action) {
  try {
    yield put(actions.speedtestsFetchAllRequest());
    const response = yield call(getAllSpeedtests, action.codeCollectivite);
    yield put(actions.speedtestsFetchAllSuccess(response));
  } catch (error) {
    yield put(actions.speedtestsFetchAllFailed(error.message));
  }
}

function* deleteSpeedtests(action) {
  try {
    yield put(actions.speedtestsDeleteRequest());
    const response = yield call(getDelete, action.codeCollectivite);
    yield put(actions.speedtestsDeleteSuccess(response));
  } catch (error) {
    yield put(actions.speedtestsDeleteFailed(error.message));
  }
}

function* createSpeedtest(action) {
  try {
    yield put(actions.speedtestCreateRequest());
    const response = yield call(getCreate, action);
    yield put(actions.speedtestCreateSuccess(response));
    yield put(actions.speedtestsFetchAll(action.codeCollectivite));
  } catch (error) {
    yield put(actions.speedtestCreateFailed(error.message));
  }
}

function* panelOpen(action) {
  try {
    yield put(actions.panelOpenRequest());
    yield put(actions.panelOpenSuccess(action.panel));
  } catch (error) {
    yield put(actions.panelOpenFailed(error.message));
  }
}

function* openPanelHistorique() {
  const data = yield select(getData);
  if (data.length !== 0) {
    yield put(actions.panelOpenSuccess('Historique'));
  }
}
function* openPanelLancerTest() {
  yield put(actions.panelOpenSuccess('Lancer un test'));
}

function* watchSpeedtests() {
  yield takeLatest(constants.SPEEDTEST_FETCH_ALL, fetchAllSpeedtests);
  yield takeLatest(constants.SPEEDTEST_DELETE, deleteSpeedtests);
  yield takeLatest(constants.SPEEDTEST_CREATE, createSpeedtest);
  yield takeLatest(constants.PANEL_OPEN, panelOpen);
  yield takeLatest(constants.SPEEDTEST_FETCH_ALL_SUCCESS, openPanelHistorique);
  yield takeLatest(constants.SPEEDTEST_DELETE_SUCCESS, openPanelLancerTest);
}

export default function* speedtestsSaga() {
  yield all([
    fork(watchSpeedtests),
  ]);
}
