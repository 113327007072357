import * as constants from '../constants/speedtests';

/**
 * Fetch All
 */
export const speedtestsFetchAll = (codeCollectivite) => ({
  type: constants.SPEEDTEST_FETCH_ALL,
  codeCollectivite,
});

export const speedtestsFetchAllRequest = () => ({
  type: constants.SPEEDTEST_FETCH_ALL_REQUEST,
});

export const speedtestsFetchAllSuccess = (speedtests) => ({
  type: constants.SPEEDTEST_FETCH_ALL_SUCCESS,
  speedtests,
});

export const speedtestsFetchAllFailed = (error) => ({
  type: constants.SPEEDTEST_FETCH_ALL_FAILED,
  error,
});

/**
 * Delete
 */
export const speedtestsDelete = (codeCollectivite) => ({
  type: constants.SPEEDTEST_DELETE,
  codeCollectivite,
});

export const speedtestsDeleteRequest = () => ({
  type: constants.SPEEDTEST_DELETE_REQUEST,
});

export const speedtestsDeleteSuccess = (speedtests) => ({
  type: constants.SPEEDTEST_DELETE_SUCCESS,
  speedtests,
});

export const speedtestsDeleteFailed = (error) => ({
  type: constants.SPEEDTEST_DELETE_FAILED,
  error,
});

/**
 * Create
 */
export const speedtestCreate = (codeCollectivite, identiteCollectivite, timestamp, ua, dl, ul, ping, jitter) => ({
  type: constants.SPEEDTEST_CREATE,
  codeCollectivite,
  identiteCollectivite,
  timestamp,
  ua,
  dl,
  ul,
  ping,
  jitter,
});

export const speedtestCreateRequest = () => ({
  type: constants.SPEEDTEST_CREATE_REQUEST,
});

export const speedtestCreateSuccess = () => ({
  type: constants.SPEEDTEST_CREATE_SUCCESS,
});

export const speedtestCreateFailed = (error) => ({
  type: constants.SPEEDTEST_CREATE_FAILED,
  error,
});

/**
 * Panel ouvert
 */

export const panelOpen = (panel) => ({
  type: constants.PANEL_OPEN,
  panel,
});

export const panelOpenRequest = () => ({
  type: constants.PANEL_OPEN_REQUEST,
});

export const panelOpenSuccess = (panel) => ({
  type: constants.PANEL_OPEN_SUCCESS,
  panel,
});

export const panelOpenFailed = (error) => ({
  type: constants.PANEL_OPEN_FAILED,
  error,
});
