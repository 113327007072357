/**
 * Fetch All
 */
export const SPEEDTEST_FETCH_ALL = '@@SPEEDTEST_FETCH_ALL';
export const SPEEDTEST_FETCH_ALL_REQUEST = '@@SPEEDTEST_FETCH_ALL/REQUEST';
export const SPEEDTEST_FETCH_ALL_SUCCESS = '@@SPEEDTEST_FETCH_ALL/SUCCESS';
export const SPEEDTEST_FETCH_ALL_FAILED = '@@SPEEDTEST_FETCH_ALL/FAILED';

/**
 * Delete
 */
export const SPEEDTEST_DELETE = '@@SPEEDTEST_DELETE';
export const SPEEDTEST_DELETE_REQUEST = '@@SPEEDTEST_DELETE/REQUEST';
export const SPEEDTEST_DELETE_SUCCESS = '@@SPEEDTEST_DELETE/SUCCESS';
export const SPEEDTEST_DELETE_FAILED = '@@SPEEDTEST_DELETE/FAILED';

/**
 * Create
 */
export const SPEEDTEST_CREATE = '@@SPEEDTEST_CREATE';
export const SPEEDTEST_CREATE_REQUEST = '@@SPEEDTEST_CREATE/REQUEST';
export const SPEEDTEST_CREATE_SUCCESS = '@@SPEEDTEST_CREATE/SUCCESS';
export const SPEEDTEST_CREATE_FAILED = '@@SPEEDTEST_CREATE/FAILED';

/**
 * Panel Open
 */
export const PANEL_OPEN = '@@PANEL_OPEN';
export const PANEL_OPEN_REQUEST = '@@PANEL_OPEN/REQUEST';
export const PANEL_OPEN_SUCCESS = '@@PANEL_OPEN/SUCCESS';
export const PANEL_OPEN_FAILED = '@@PANEL_OPEN/FAILED';
