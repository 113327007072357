import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import sagaMiddlewareFactory from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

// eslint-disable-next-line
const composer = typeof window === 'undefined' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  let initialState;
  if (process.NODE_ENV !== 'production') {
        initialState = undefined;// eslint-disable-line
  } else {
    initialState = localStorage.getItem('state');
  }

    if (initialState !== undefined) {// eslint-disable-line
    initialState = JSON.parse(initialState);
  }

  const sagaMiddleware = sagaMiddlewareFactory();

  const store = createStore(
    rootReducer,
    initialState,
    composer(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  // store.subscribe(() => {
  //     localStorage.setItem('state', JSON.stringify(store.getState())); // eslint-disable-line
  // });

  return store;
}
