import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';

import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import {
  Table,
  Dropdown,
  // Input,
  Grid,
  Button,
  Segment,
  Menu,
  Pagination,
  Container,
} from '@jvs-group/jvs-mairistem-composants';
import {
  Input,
  Modal,
} from 'semantic-ui-react';

import * as actions from '../store/actions';

/**
 * Historique Panel.
 *
 * @author Axel MORLET
 * @class PanelHistorique
 * @extends {React.Component}
 */
class PanelHistorique extends React.Component {
  /**
     * Prop types.
     *
     * @static
     * @memberof PanelHistorique
     */
  static propTypes = {
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
  };

  /**
     * Creates an instance of PanelHistorique.
     *
     * @param {any} args
     * @memberof PanelHistorique
     */
  constructor(...args) {
    super(...args);
    this.state = {
      data: this.props.data,
      number: '',
      identite: '',
      resultat: null,
      comparaison: null,
      startDate: null,
      endDate: null,
      currentPage: 1,
      lastPage: _.ceil(this.props.data.length / 10),
      open: false,
    };
    moment.locale('fr');
  }

  /**
     * Handle change the dropdown's resultat value.
     *
     * @param {any} event,{value}
     * @memberof PanelHistorique
     */
  handleChangeResultat = (event, { value }) => {
    this.setState({ resultat: value });
  };

  /**
     * Handle change the dropdown's comparaison value.
     *
     * @param {any} event,{value}
     * @memberof PanelHistorique
     */
  handleChangeComparaison = (event, { value }) => {
    this.setState({ comparaison: value });
  };

  /**
     * Handle change the input's number.
     *
     * @param {any} event
     * @memberof PanelHistorique
     */
  handleChangeNumber = (event) => {
    const { value } = event.target;

    this.setState({ number: value });
  };

  /**
     * Handle change the input's identite.
     *
     * @param {any} event
     * @memberof PanelHistorique
     */
  handleChangeIdentite = (event) => {
    const { value } = event.target;

    this.setState({ identite: value });
  };

  /**
     * Filter the data with the different dropdown's value and the input's value.
     *
     * @memberof PanelHistorique
     */
  filterData = () => {
    const {
      resultat,
      comparaison,
      number,
      startDate,
      endDate,
      identite,
    } = this.state;

    const { data } = this.props;

    let tmp = [];
    // Filtre les différentes options (résultat, comparaison, number)
    if (!_.isNil(resultat) && !_.isNil(comparaison) && number !== '') {
      switch (resultat) {
        case 'Download':
          switch (comparaison) {
            case 'sup':
              _.map(data, (enregistrement) => {
                if (enregistrement.dl > number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            case 'inf':
              _.map(data, (enregistrement) => {
                if (enregistrement.dl < number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            default:
              break;
          }
          break;
        case 'Upload':
          switch (comparaison) {
            case 'sup':
              _.map(data, (enregistrement) => {
                if (enregistrement.ul > number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            case 'inf':
              _.map(data, (enregistrement) => {
                if (enregistrement.ul < number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            default:
              break;
          }
          break;
        case 'Ping':
          switch (comparaison) {
            case 'sup':
              _.map(data, (enregistrement) => {
                if (enregistrement.ping > number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            case 'inf':
              _.map(data, (enregistrement) => {
                if (enregistrement.ping < number) {
                  tmp.push(enregistrement);
                }
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      this.setState({
        data: tmp,
        currentPage: 1,
        lastPage: _.ceil(tmp.length / 10),
      });
    }

    let tmp2 = [];
    // Filtre la date
    if (!_.isNil(startDate) && !_.isNil(endDate)) {
      if (tmp.length === 0 && number === '') {
        tmp = data;
      }

      let startMonth = startDate.month() + 1;
      let startDay = startDate.date();
      let endMonth = endDate.month() + 1;
      let endDay = endDate.date();

      if (startMonth <= 9) startMonth = `0${startMonth}`;
      if (endMonth <= 9) endMonth = `0${endMonth}`;
      if (startDay <= 9) startDay = `0${startDay}`;
      if (endDay <= 9) endDay = `0${endDay}`;

      const start = new Date(startDate.year(), startMonth - 1, startDay);
      const end = new Date(endDate.year(), endMonth - 1, endDay);

      _.map(tmp, (enregistrement) => {
        const check = new Date(
          enregistrement.timestamp.substring(6, 10),
          enregistrement.timestamp.substring(3, 5) - 1,
          enregistrement.timestamp.substring(0, 2),
        );

        if (check.getTime() >= start.getTime() && check.getTime() <= end.getTime()) {
          tmp2.push(enregistrement);
        }
      });

      this.setState({ data: tmp2, currentPage: 1, lastPage: _.ceil(tmp2.length / 10) });
    }

    // Filtre l'identite
    if (identite !== '') {
      if (tmp2.length === 0 && _.isNil(startDate) && _.isNil(endDate)) {
        if (tmp.length === 0 && _.isNil(resultat) && _.isNil(comparaison) && number === '') {
          tmp2 = data;
        } else {
          tmp2 = tmp;
        }
      }

      const tmp3 = [];

      _.map(tmp2, (enregistrement) => {
        if (_.includes(_.toLower(enregistrement.identite), _.toLower(identite))) {
          tmp3.push(enregistrement);
        }

        this.setState({ data: tmp3, currentPage: 1, lastPage: _.ceil(tmp3.length / 10) });
      });
    }
  };

  /**
     * Reset the filter.
     *
     * @memberof PanelHistorique
     */
  resetFilter = () => {
    const { data } = this.props;

    this.setState({
      data,
      number: '',
      identite: '',
      resultat: null,
      comparaison: null,
      startDate: null,
      endDate: null,
      currentPage: 1,
      lastPage: _.ceil(data.length / 10),
    });
  };

  /**
     * Handle Pagination change.
     *
     * @memberof PanelHistorique
     */
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  /**
     * Handle open state for the delete modal.
     *
     * @memberof PanelHistorique
     */
  handleOpenModal = (open) => {
    this.setState({ open });
  };

  /**
     * Handle delete speedtests.
     *
     * @memberof PanelHistorique
     */
  handleDelete = () => {
    const { deleteSpeedtests } = this.props;

    deleteSpeedtests(document.getElementById('codeCollectivite').value);
    this.handleOpenModal(false);
  };

  /**
     * Render the component.
     *
     * @memberof PanelHistorique
     */
  render() {
    const {
      data, resultat, comparaison, number, currentPage, lastPage, identite, open,
    } = this.state;

    const optionsResultats = [
      { key: 1, text: 'Download', value: 'Download' },
      { key: 2, text: 'Upload', value: 'Upload' },
      { key: 3, text: 'Ping', value: 'Ping' },
    ];

    const optionsComparaison = [
      { key: 1, text: 'supérieur à', value: 'sup' },
      { key: 2, text: 'inférieur à', value: 'inf' },
    ];

    const dataDivided = _.chunk(data, 10);
    const goodData = dataDivided[currentPage - 1];

    return (
      <>
        <Segment style={{ flexGrow: 0 }}>
          <Menu as={Grid} stackable text compact>
            <Grid.Row centered style={{ padding: 0 }}>
              <Menu.Item>
                <Input placeholder="Identité" style={{ width: '18vw' }} value={identite} onChange={this.handleChangeIdentite} />
              </Menu.Item>
              <Menu.Item>
                <Dropdown placeholder="Résultat" selection options={optionsResultats} value={resultat} onChange={this.handleChangeResultat} />
              </Menu.Item>
              <Menu.Item>
                <Dropdown placeholder="Comparaison" selection options={optionsComparaison} value={comparaison} onChange={this.handleChangeComparaison} />
              </Menu.Item>
              <Menu.Item>
                <Input
                  className="valueMbps"
                  label={{ basic: true, content: resultat === 'Ping' ? 'ms' : 'Mbps' }}
                  value={number}
                  labelPosition="right"
                  size="tiny"
                  onChange={this.handleChangeNumber}
                />
              </Menu.Item>
              <Menu.Item>
                <DateRangePicker
                  startDate={this.state.startDate}
                  startDateId="startDateId"
                  endDate={this.state.endDate}
                  endDateId="endDateId"
                  onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                  monthFormat="MMMM"
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={() => false}
                  showDefaultInputIcon
                  inputIconPosition="before"
                  startDatePlaceholderText="Date début"
                  endDatePlaceholderText="Date fin"
                  hideKeyboardShortcutsPanel
                  small
                  orientation="vertical"
                />
              </Menu.Item>
            </Grid.Row>
            <Grid.Row centered style={{ paddingBottom: 0 }}>
              <Menu.Item>
                <Button basic onClick={this.filterData}>Filtrer</Button>
                <Menu.Item />
                <Button basic onClick={this.resetFilter}>Réinitialiser</Button>
              </Menu.Item>
            </Grid.Row>
          </Menu>
        </Segment>
        <Table celled style={{ marginTop: 0 }} striped className="tableHistorique">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code collectivité</Table.HeaderCell>
              <Table.HeaderCell>Identité</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Heure</Table.HeaderCell>
              <Table.HeaderCell>Download (Mbps)</Table.HeaderCell>
              <Table.HeaderCell>Upload (Mbps)</Table.HeaderCell>
              <Table.HeaderCell>Ping (ms)</Table.HeaderCell>
              <Table.HeaderCell>Jitter (ms)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { (_.isNil(goodData) || goodData.length === 0)
              ? (
                <Table.Row>
                  <Table.Cell colSpan={7} textAlign="center">Aucun résultat</Table.Cell>
                </Table.Row>
              )
              : _.map(goodData, (enregistrement) => (
                <Table.Row key={`row-${enregistrement.timestamp}-${enregistrement.dl}-${enregistrement.ul}`}>
                  <Table.Cell key={`cell-code-${enregistrement.collectivite}`}>{enregistrement.collectivite}</Table.Cell>
                  <Table.Cell key={`cell-code-${enregistrement.identite}`}>{enregistrement.identite}</Table.Cell>
                  <Table.Cell key={`cell-date-${enregistrement.timestamp.substring(0, 10)}`}>{enregistrement.timestamp.substring(0, 10)}</Table.Cell>
                  <Table.Cell key={`cell-heure-${enregistrement.timestamp.substring(11, 16)}`}>{enregistrement.timestamp.substring(11, 16)}</Table.Cell>
                  <Table.Cell key={`cell-dl-${enregistrement.dl}`}>{enregistrement.dl.toFixed(2)}</Table.Cell>
                  <Table.Cell key={`cell-ul-${enregistrement.ul}`}>{enregistrement.ul.toFixed(2)}</Table.Cell>
                  <Table.Cell key={`cell-ping-${enregistrement.ping}`}>{enregistrement.ping.toFixed(2)}</Table.Cell>
                  <Table.Cell key={`cell-jitter-${enregistrement.jitter}`}>{enregistrement.jitter.toFixed(2)}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={8}>
                <Container fluid textAlign="center">
                  <Pagination
                    activePage={currentPage}
                    totalPages={lastPage}
                    onPageChange={this.handlePaginationChange}
                    secondary
                    pointing
                  />
                </Container>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        { !_.isNil(data) && data[0].collectivite !== 99999 && data.length > 0
                && (
                <>
                  <Grid.Row>
                    <Button fluid onClick={() => this.handleOpenModal(true)}>Effacer les enregistrements de la collectivité</Button>
                  </Grid.Row>
                  <Modal open={open} onClose={() => this.handleOpenModal(false)}>
                    <Modal.Header>
                      Attention
                    </Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        Êtes-vous sur de vouloir supprimer tous les enregistrements de cette collectivité ?
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={() => this.handleOpenModal(false)}>Non</Button>
                      <Button onClick={this.handleDelete}>Oui</Button>
                    </Modal.Actions>
                  </Modal>
                </>
                )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.allSpeedtest,
});

const mapDispatchToProps = {
  deleteSpeedtests: actions.speedtestsDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelHistorique);
