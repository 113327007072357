import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';
import 'semantic-ui-css/semantic.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App />,
);
