import _ from 'lodash';
import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Dropdown,
  Menu,
  Segment,
  Grid,
} from '@jvs-group/jvs-mairistem-composants';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(...registerables);

/**
 * Graphique Panel.
 *
 * @author Axel MORLET
 * @class PanelGraphique
 * @extends {React.Component}
 */
class PanelGraphique extends React.Component {
  /**
     * Prop types.
     *
     * @static
     * @memberof PanelGraphique
     */
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  /**
     * Creates an instance of PanelGraphique.
     *
     * @param {any} args
     * @memberof PanelGraphique
     */
  constructor(...args) {
    super(...args);
    this.state = {
      data: [],
      value: '',
    };
  }

  /**
     * ComponentDidMount.
     *
     * @memberof PanelGraphique
     */
  componentDidMount() {
    const { data } = this.props;
    const week = this.getCurrentWeek();
    const tmp = [];
    const tmpLabels = [];
    const tmpData = _.reverse(_.cloneDeep(data));
    _.map(tmpData, (enregistrement) => {
      const compareDate = `${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)}`;
      if (_.indexOf(week, compareDate) !== -1) {
        tmp.push(enregistrement);
        tmpLabels.push(`${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)} ${enregistrement.timestamp.substring(11, 16)}`);
      }
    });
    if (tmp.length === 1) {
      tmp.push(tmp[0]);
      tmpLabels.push(tmpLabels[0]);
    }
    this.setState({ data: tmp, labelsChart: tmpLabels });
  }

  /**
     * Get download value for chart.
     *
     * @memberof PanelGraphique
     */
  getDownloadValueForChart() {
    const { data } = this.state;
    const res = [];
    if (!_.isNil(data)) {
      data.forEach((enregistrement) => {
        res.push(enregistrement.dl);
      });
    }
    return res;
  }

  /**
     * Get upload value for chart.
     *
     * @memberof PanelGraphique
     */
  getUploadValueForChart() {
    const { data } = this.state;
    const res = [];
    if (!_.isNil(data)) {
      data.forEach((enregistrement) => {
        res.push(enregistrement.ul);
      });
    }
    return res;
  }

  /**
     * Handle change Dropdown filter.
     *
     * @memberof PanelGraphique
     */
  handleChangeDrodpown = (event, { value }) => {
    this.setState({ value });
    const { data } = this.props;
    const tmp = [];
    const tmpLabels = [];
    const week = this.getCurrentWeek();
    const date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    const tmpData = _.reverse(_.cloneDeep(data));
    if (month <= 9) {
      month = `0${month}`;
    }
    if (day <= 9) {
      day = `0${day}`;
    }

    switch (value) {
      case "Aujourd'hui":
        _.map(tmpData, (enregistrement) => {
          if (`${day}/${month}` === `${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)}`) {
            tmp.push(enregistrement);
            tmpLabels.push(`${enregistrement.timestamp.substring(11, 16)}`);
          }
        });
        if (tmp.length === 1) {
          tmp.push(tmp[0]);
          tmpLabels.push(tmpLabels[0]);
        }
        break;
      case 'Cette semaine':
        _.map(tmpData, (enregistrement) => {
          const compareDate = `${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)}`;
          if (_.indexOf(week, compareDate) !== -1) {
            tmp.push(enregistrement);
            tmpLabels.push(`${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)} ${enregistrement.timestamp.substring(11, 16)}`);
          }
        });
        if (tmp.length === 1) {
          tmp.push(tmp[0]);
          tmpLabels.push(tmpLabels[0]);
        }
        break;
      case 'Ce mois':
        _.map(tmpData, (enregistrement) => {
          let compareMonth = _.parseInt(enregistrement.timestamp.substring(3, 5));
          compareMonth = compareMonth <= 9 ? `0${compareMonth}` : compareMonth;
          if (compareMonth === month) {
            tmp.push(enregistrement);
            tmpLabels.push(`${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)} ${enregistrement.timestamp.substring(11, 16)}`);
          }
        });
        if (tmp.length === 1) {
          tmp.push(tmp[0]);
          tmpLabels.push(tmpLabels[0]);
        }
        break;
      case 'Tous les tests':
        _.map(tmpData, (enregistrement) => {
          tmp.push(enregistrement);
          tmpLabels.push(`${enregistrement.timestamp.substring(0, 2)}/${enregistrement.timestamp.substring(3, 5)} ${enregistrement.timestamp.substring(11, 16)}`);
        });

        if (tmp.length === 1) {
          tmp.push(tmp[0]);
          tmpLabels.push(tmpLabels[0]);
        }
        break;
      default:
        break;
    }
    this.setState({ data: tmp, labelsChart: tmpLabels });
  };

  /**
     * Get current week.
     *
     * @memberof PanelGraphique
     */
  getCurrentWeek = () => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    const week_start_tstmp = today.setDate(diff);
    let week_start = new Date(week_start_tstmp);
    const list = [];

    list.push(`${week_start.getDate() <= 9 ? `0${week_start.getDate()}` : week_start.getDate()}/${week_start.getMonth() + 1 <= 9 ? `0${week_start.getMonth() + 1}` : week_start.getMonth() + 1}`);
    for (var i = 0; i < 6; i++) {
      const tomorrow = new Date(week_start);
      tomorrow.setDate(week_start.getDate() + 1);
      list.push(`${tomorrow.getDate() <= 9 ? `0${tomorrow.getDate()}` : tomorrow.getDate()}/${tomorrow.getMonth() + 1 <= 9 ? `0${tomorrow.getMonth() + 1}` : tomorrow.getMonth() + 1}`);
      week_start = tomorrow;
    }
    return list;
  };

  /**
     * Render the component.
     *
     * @memberof PanelGraphique
     */
  render() {
    const { labelsChart } = this.state;
    const dataDownload = this.getDownloadValueForChart();
    const dataUpload = this.getUploadValueForChart();
    var data = {
      labels: labelsChart,
      datasets: [
        {
          label: 'Download (Mbps)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(2, 219, 252,1)',
          borderColor: 'rgba(2, 219, 252,1)',
          borderCapStyle: 'butt',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(2, 219, 252,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(2, 219, 252,1)',
          pointHoverBorderColor: 'rgba(0,0,0,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataDownload,
        },
        {
          label: 'Upload (Mbps)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(252, 89, 2, 1)',
          borderColor: 'rgba(252, 89, 2, 1)',
          borderCapStyle: 'butt',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(252, 89, 2, 1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(252, 89, 2, 1)',
          pointHoverBorderColor: 'rgba(0,0,0,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataUpload,
        },
      ],
    };
    const optionsDropdown = [
      { key: '1', text: "Aujourd'hui", value: "Aujourd'hui" },
      { key: '2', text: 'Cette semaine', value: 'Cette semaine' },
      { key: '3', text: 'Ce mois', value: 'Ce mois' },
      { key: '4', text: 'Tous les tests', value: 'Tous les tests' },
    ];

    return (
      <>
        <Menu as={Grid} text style={{ marginTop: 0, marginBottom: 0 }}>
          <Menu.Item style={{ paddingTop: 0 }}>
            <Dropdown
              defaultValue="Cette semaine"
              selection
              options={optionsDropdown}
              onChange={this.handleChangeDrodpown}
            />
          </Menu.Item>
        </Menu>
        <Segment style={{ backgroundColor: '#FFFFFF', marginTop: 0 }}>
          <Line data={data} width={600} height={250} />
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.allSpeedtest,
});

export default connect(mapStateToProps)(PanelGraphique);
